import React, {Component} from 'react';
import Newservices from '../Common/Newservices';
import Footer from '../Common/Footer';


class Services extends Component {
    render(){
        return(
           
            <div>
                               
                <Newservices />
                <Footer />   
            </div>
        )
    }
}

export default Services;