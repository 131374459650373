import React, {Component} from 'react';


class About extends Component

{

    render() {
        return(
    <div>
            <section className="page-section" id="about">
            <div className="container">
                        <div className="text-center">
                            <h2 className="section-heading text-uppercase large text-muted">Our Journey</h2>
                            <h3 className="section-subheading text-muted">
                                    A family-run company called Bubmaq-G was founded in northern Nigeria. 
                                    It was created in order to meet the community members' growing demands. 
                                    One of the main goals Bubmaq-G to this day has, is to <strong>provide healthy food and clean water </strong> to the residents of the middle belt. 
                                    In addition to real estate, catering, event management, general contracting, commercial transportation, and training programs focused on empowering individuals and families,
                                    we have now expanded our services beyond agricultural and water production.
                                    <br/><strong>To us its all about giving to our community!</strong></h3>
                        </div>
                        <div className="">
                            
                                <div  className="large text-muted " >
                                    <h4 className="section-heading text-uppercase text-center">Our Strategy</h4>
                                    <ul style={{listStyle:'none', display: 'block', marginLleft: 'auto', marginRright: 'auto', width: '100%'}}className="text-muted rounded p-[30px] bg-[#E8D5C4]" >
                                    <li><strong>Purpose:</strong> To be a leader in the real estate industry by providing enhanced services, relationship and profitability.
                                    </li>
                                    <li><strong>Mission Statement:</strong> To build long term relationships with our customers and clients and provide exceptional customer services by pursuing business through innovation and advanced technology.
                                    </li>
                                    <li><strong>Vision:</strong> To provide quality services that exceeds the expectations of our esteemed customers.
                                    </li>
                                </ul>
                                

                                <h4 className="section-subheading text-uppercase text-center">Core Values</h4>
                                <ul style={{listStyle:'none', display: 'block', marginLleft: 'auto', marginRright: 'auto', width: '100%'}}className="text-muted rounded p-[30px] bg-[#E8D5C4]" >
                                    <li>
                                    We believe in treating our customers with respect and faith.
                                    </li>
                                    <li>
                                    We grow through creativity, invention and innovation.
                                    </li>
                                    <li>
                                    We integrate honesty, integrity and business ethics into all aspects of our business functioning.
                                    </li> 
                                </ul>

                            <br></br>
                               
                                    <h4 className="section-subheading text-uppercase text-center"> Goals</h4>
                                    <ul style={{listStyle:'none', display: 'block', marginLleft: 'auto', marginRright: 'auto', width: '100%'}}className="text-muted rounded p-[30px] bg-[#E8D5C4]" >
                                        <li>
                                        Regional expansion in the field of property management and develop a strong base of key customers.
                                        </li>
                                        <li>
                                        Increase the assets and investments of the company to support the development of services.
                                        </li>
                                        <li>
                                        To build good reputation in the field of real estate and property management and become a key player in the industry.
                                        </li> 
                                    </ul>
                                                   
                            </div>
                            
                            <br></br>
                        </div>
                        <h4 className="section-subheading text-uppercase text-center"> Scope of Work </h4>
                        <p className="text-center text-muted">
                            Bubmaq-G Investment Limited Conducts businesses in various sectors such;
                        </p>
                        <ul className="timeline">
                        <li className="timeline-inverted">
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/11.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Educational Development Consultation</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">We help institutions develop their curriculums and workplace organizational flow.</p></div>
                                </div>
                            </li>
                        <li>
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Travel and Tour Consulting</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">We process Travel plans for individuals, families, groups and organizations. We take care of everything related to your travels. For trainings, vacations, foreign study and tourism.</p></div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Visa Processing</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">We offer a specialized service on Visa consultation, processing and application. We will be with you at every step of the way. We specialize in Canadian Visa, USA Visa, UK Visa, Shengen Visa and UAE Visa. We also help in securing admission for Diploma, Bachelors, Masters and PHD degree in the above countries.</p></div>
                                </div>
                            </li>
                           
                            <li>
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>General Contracting</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">We handle general contracts including trading, supply of goods and services and real-estate rental services.</p></div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Custom on Demand Software Development</h4>
                                        
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">We handle on demand Software Development and Deployment for Software Plugins and or Standalone Software</p></div>
                                </div>
                            </li>
                            
                            <li>
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                       
                                        <h4 className="subheading">Our Catering Sector</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">Our catering industry is intended to be flavorful and inventive. African specialties are produced as part of our catering services. We make an effort to maintain taste quality.</p></div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                    <h4 className="subheading">Water Production</h4>
                                       
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">In 2017 our Water production sector was developed. Our community now has access to clean water thanks to the development of our water production sector.</p></div>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Agriculture</h4>
                                        <h4 className="subheading">Fresh crops and Livestock</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">Fresh organic farm products are quickly expanding in our lucrative agriculture sector, serving our community.</p></div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Events Management</h4>
                                        <h4 className="subheading">Weddings, Parties and more!</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">We handle and arrange events, provide culinary and bakery services, hospitality services, interior and exterior decoration, and function as promoters of entertainment.</p></div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image">
                                    <h4>
                                        Be Part
                                        <br />
                                        Of Our
                                        <br />
                                        Story!
                                    </h4>
                                </div>
                            </li>
                        </ul>
                    </div>
             </section>

        </div>
        )
    }
    
}

export default About;
